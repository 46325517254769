import {
  Bars3Icon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import { useFilterStore } from '../../store/filterStore';
import { useEffect, useRef, useState } from 'react';
import { useAuthStore } from '../../store/authStore';
import { useUserStore } from '../../store/userStore';
import { getAllUser, createUser, updateSettings, getUserAgreementStatus } from '../../utils/request_user';
import ProjectsDropdown from './ProjectDropdown';
import CreateNewProjectModal from '../modals/CreateNewProjectModal';
import { useSearchStore } from '../../store/searchStore';
import { useAdvancedFilterStore } from '../../store/advancedFiltersStore';
import { usePatientStore } from '../../store/patientStore';
import { useSearchParamsStore } from '../../store/searchParamsStore';
import { useSearchTypeStore } from '../../store/searchTypeStore';
import { useNewsModalStore } from '../../store/newsModalStore';
import { UserButton, useUser } from '@clerk/clerk-react';
import {  getSearchParams } from '../../utils/search_params';
import { usePatient1AdvancedFiltersStore } from '../../store/patient1AdvancedFiltersStore';
import { usePatient2AdvancedFiltersStore } from '../../store/patient2AdvancedFiltersStore';
import EditProjectNameModal from '../modals/EditProjectNameModal';
import NewsModal from '../modals/NewsModal';
import { getNewsContent } from '../../utils/request_news';
import { useAgreementModalStore } from '../../store/agreementModalStore';
import AgreementModal from '../modals/TermsOfService/AgreementModal';
import { getTeamProjects } from '../../utils/request_for_team_projects';
import { Project, TeamsCohort } from '../../types/project';
import DeleteProjectModal from '../modals/DeleteProjectModal';
import { useModalStore } from '../../store/modalStore';
import { useSortStore } from '../../store/sortStore';
import {useUpdateFilter}  from '../../utils/updateFilter';
import { useSavedSearchStore } from '../../store/savedSeachStore';
import { urlSearchId } from "../../utils/url_search_id";
import { useSnomedSearchStore } from '../../store/snomedSearchStore';
import {  ParentItem, SearchSnomedTerm, SubChild } from "../../types/search";
import { getSnomedSearchDropDownLists } from '../../utils/request_studies';
import { useProjectsStore } from '../../store/projectsStore';
import axios, { CancelTokenSource } from 'axios';

 
export default function TopNavigation() {
    const { isLoaded, user } =  useUser();

    // Auth state
    const token = useAuthStore(state => state.token);

    // Filter State
    const isFilterPanelOpen = useFilterStore(state => state.isFiltersPanelOpen);
    const setIsFiltersPanelOpen = useFilterStore(state => state.setIsFiltersPanelOpen);
    const setSearchTerms = useFilterStore(state => state.setSearchTerms);
    const setFiltersDisabled = useFilterStore(state => state.setFiltersDisabled);
    const setTimeBetween = usePatientStore(state => state.setTimeBetween);

    // Advanced Filter State
    const setFilterGroups = useAdvancedFilterStore(state => state.setFilterGroups);
    const setAdvancedFiltersEnabled = useAdvancedFilterStore(state => state.setAdvancedFiltersEnabled);
    const setAdvancedFilters1 = usePatient1AdvancedFiltersStore(state => state.setAdvancedFilters);
    const setAdvancedFilters2 = usePatient2AdvancedFiltersStore(state => state.setAdvancedFilters);
    const setFilterGroups1 = usePatient1AdvancedFiltersStore(state => state.setFilterGroups);
    const setFilterGroups2 = usePatient2AdvancedFiltersStore(state => state.setFilterGroups);
    const setAdvancedFiltersEnabled1 = usePatient1AdvancedFiltersStore(state => state.setAdvancedFiltersEnabled);
    const setAdvancedFiltersEnabled2 = usePatient2AdvancedFiltersStore(state => state.setAdvancedFiltersEnabled);

    // User state
    const projects = useUserStore(state => state.projects);
    const setProjects = useUserStore(state => state.setProjects);
    const setSelectedProject = useUserStore(state => state.setSelectedProject);
    const setGradientUser = useUserStore(state => state.setGradientUser);
    const setOrganizationProjects= useUserStore(state => state.setOrganizationProjects);
    const selectedProject = useUserStore(state => state.selectedProject);
    const setProjectsBelongsToUser= useUserStore(state => state.setProjectsBelongsToUser);
    const setSelectedProjectOwnerEmail = useUserStore(state => state.setSelectedProjectOwnerEmail);

    // Patient state
    const setPatientFilters = usePatientStore(state => state.setPatientFilters);
    const setPatientPaginationOffset = usePatientStore(state => state.setPatientPaginationOffset);
    const setSelectedPatientPageSize = usePatientStore(state => state.setSelectedPatientPageSize);
    const setPatientPagination = usePatientStore(state => state.setPatientPagination);

    // Search State
    const setStudiesPagination = useSearchStore(state => state.setStudiesPagination);

    //Project State
    const setIsTeamProjectLoading = useProjectsStore(state => state.setIsTeamProjectLoading);

    // Snomed Search state
    const setSnomedSearchRequest = useSnomedSearchStore(state => state.setSnomedSearchRequest);
    const setSnomedPaginationOffset = useSnomedSearchStore(state => state.setSnomedSearchRequestPageOffset);
    const setSelectedSnomedPageSize = useSnomedSearchStore(state => state.setSelectedPageSize);
    const setSnomedDropDownDataArray = useSnomedSearchStore(state => state.setSnomedDropDownDataArray); 
    const setIsSnomedSearchLoader = useSnomedSearchStore(state => state.setIsSnomedSearchLoader); 

    // Search Params state
    const setSearchParamsLoaded = useSearchParamsStore(state => state.setSearchParamsLoaded);

    // Search Type state
    const searchType = useSearchTypeStore(state => state.searchType);
    const setSearchType = useSearchTypeStore(state => state.setSearchType);

    // Cross filter state
    const setCrossFilterOnly =  useFilterStore(state => state.setCrossFilterOnly);

    // News modal state
    const releaseDate= useNewsModalStore(state => state.releaseDate);
    const setReleaseDate = useNewsModalStore(state => state.setReleaseDate);
    const isNewsOpen = useNewsModalStore(state => state.isNewsOpen);
    const setIsNewsOpen =  useNewsModalStore(state => state.setIsNewsOpen);
    const newsContent =  useNewsModalStore(state => state.newsContent);
    const setNewsContent =  useNewsModalStore(state => state.setNewsContent);

    // Agreement Modal store
    const showAgreementModal = useAgreementModalStore((state) => state.showAgreementModal);
    const setShowTermsOfServiceModal = useAgreementModalStore(state => state.setShowTermsOfServiceModal)

    //setting agreement version
    const latest_contract_version = "V1";

    //model store
    const showDeleteProjectModal = useModalStore(state => state.showDeleteProjectModal);

    // sort store
    const updateStudySortArray = useSortStore((state) => state.newStudySortArray);
    const updatePatientSortArray = useSortStore((state) => state.newPatientSortArray);

    // Saved search state
    const setIsSearchChanged = useSavedSearchStore(state => state.setIsSearchChanged);
    
    const axiosGetAllSource = useRef<CancelTokenSource | null>(null);

    // Local State
    const [ userInitiated, setUserInitiated ] = useState(false);
    const [contractVersion, setContractVersion] = useState("");
    const [agreementStatus, setAgreementStatus] = useState("");

    const [teamProjects, setTeamProjects] = useState<TeamsCohort[]>([]);


    const {updateFilter} = useUpdateFilter();

    useEffect(() => {
        if (token && isLoaded && user && projects.length === 0 && !userInitiated) {
            setFiltersDisabled(true);
            setIsSearchChanged(false);
            const getAll = async () => {
              try {
                if (axiosGetAllSource.current) {
                  axiosGetAllSource.current.cancel("Canceling previous request");
                }
                // Create a new CancelTokenSource for the current request
                axiosGetAllSource.current = axios.CancelToken.source();
                let allUser = await getAllUser(token, { cancelToken: axiosGetAllSource.current.token });
                if (allUser.status === 500) {
                    await createUser(user.primaryEmailAddress?.emailAddress, token);
                    if (axiosGetAllSource.current) {
                      axiosGetAllSource.current.cancel("Canceling previous request");
                    }
                    // Create a new CancelTokenSource for the current request
                    axiosGetAllSource.current = axios.CancelToken.source();
                    allUser = await getAllUser(token, { cancelToken: axiosGetAllSource.current.token });
                    setProjects(allUser.data.projects);
                    setSelectedProject(allUser.data.projects[0]);
                    setSelectedProjectOwnerEmail(allUser.data.email);
                    setGradientUser({
                        email: allUser.data.email,
                        role: allUser.data.role,
                        subscribed: allUser.data.subscribed
                    })
                    setFiltersDisabled(false);
                    setUserInitiated(true);
                } else {
                    setProjects(allUser.data.projects);
                    let initialSelectedProject:Project = { ...allUser.data.projects[0],email: allUser.data.email }
                    setSelectedProject(initialSelectedProject);
                    setSelectedProjectOwnerEmail(allUser.data.email);
                    setGradientUser({
                        email: allUser.data.email,
                        role: allUser.data.role,
                        subscribed: allUser.data.subscribed
                    })
                    setFiltersDisabled(false);
                    setUserInitiated(true);
                    if(urlSearchId === "" || urlSearchId === undefined){
                        if (allUser.data.settings.search_request) {
                          const search_request = allUser.data.settings.search_request;
                          if(search_request.filters && search_request.filters.sex) {
                            if (search_request.filters.sex.find((value:any) => value === 'O') && search_request.filters.sex.find((value:any) => value !== 'U')) {
                              search_request.filters.sex.push('U');
                            }
                          }
                          if (search_request.filters) {
                              const filters = search_request.filters;
                              updateFilter(filters);
                          }
                        }
                        const searchParams = new URLSearchParams(document.location.search);
                        if (searchParams.get("search") == null) {
                            if (allUser.data.settings.snomed_search_request.searchTerms?.length > 0) {
                              const snomed_search_request = allUser.data.settings.snomed_search_request;
                              if(snomed_search_request.filters && snomed_search_request.filters.sex) {
                                if (snomed_search_request.filters.sex.find((value:any) => value === 'O') && snomed_search_request.filters.sex.find((value:any) => value !== 'U')) {
                                  snomed_search_request.filters.sex.push('U');
                                }
                              }
                              if (snomed_search_request.filters) {
                                const filters = snomed_search_request.filters;
                                updateFilter(filters);
                              }
                              setSnomedSearchRequest(allUser.data.settings.snomed_search_request);
                              BuildSnomedTagDropdown(allUser.data.settings.snomed_search_request.searchTerms);
                            } else if (allUser.data.settings.search_terms) {
                              setSearchTerms(allUser.data.settings.search_terms);
                            }
                            if (allUser.data.settings.filter_groups) {
                                if (allUser.data.settings.filter_groups.length) {
                                    setAdvancedFiltersEnabled(true);
                                    setFilterGroups(allUser.data.settings.filter_groups);
                                }
                            }
                            setSearchParamsLoaded(true);
                        } else {
                            let searchString = searchParams.get("search") ? decodeURI(searchParams.get("search") as string) : ""
                            const search = JSON.parse(searchString)
                            if (search.searchType === "study") {
                              if (search.filterGroups) {
                                if (search.filterGroups.length) {
                                  setAdvancedFiltersEnabled(true);
                                  setFilterGroups(search.filterGroups);
                                }
                              }
                              if (search.searchTerms) {
                                const filters = search.search.filters;
                                updateFilter(filters);
                                let searchTermsToSnomed:SearchSnomedTerm[] = [];
                                search.searchTerms.forEach((searchTerm: any) => {
                                  searchTermsToSnomed.push({operator:searchTerm.operator,parent_snomed_tag:{cui:0,term:""},snomed_tags:[],term:searchTerm.term})
                                });
                                searchTermsToSnomed.length>0 && setSnomedSearchRequest({filters:search.search.filters,is_snomed_search:false,search_query:search.search.search_query,searchTerms:searchTermsToSnomed,pagination:search.search.pagination,sort:search.search.sort})
                              } else {
                                setSnomedSearchRequest(search.snomed_search_request);
                                BuildSnomedTagDropdown(search.snomed_search_request.searchTerms);
                                updateFilter(search.snomed_search_request.filters)
                              }
                            } else if (search.searchType === "patient") {
                                setSearchType("patient")
                                setPatientFilters(search.search)
                            }
                    }
                        // To prevent the backend cacheing from superceding the current search
                        setTimeout(() => {
                            setSearchParamsLoaded(true);
                        }, 3000)
                    }

                }
              } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.error(error);
                }
            }
            }
            getAll();
        }
    }, [token, user])

    const setIsCheckedRecursively = (data: ParentItem[]): SubChild[] => {
      return data.map((item) => ({
        ...item,
        isChecked: true,
        children: item.children ? setIsCheckedRecursively(item.children) : [],
      }));
    };

    const BuildSnomedTagDropdown = async (tagSearchTerm: ParentItem[]) => {
      try {
        const initialDropdownData = tagSearchTerm
          .filter((tag: any) => tag.parent_snomed_tag.term !== "")
          .map((snomedSearchTag: any) => ({
            term: snomedSearchTag.parent_snomed_tag.term,
            cui: snomedSearchTag.parent_snomed_tag.cui,
            operator: snomedSearchTag.operator,
            isChecked: true,
            children: [],
          }));

        setSnomedDropDownDataArray(initialDropdownData);
        const tempSnomedDropDownDataArray = await Promise.all(
          tagSearchTerm.map(async (snomedSearchTag: any) => {
            if (snomedSearchTag?.parent_snomed_tag?.term) {
              // Improved condition to avoid empty terms
              let updatedDropDownData: any = {
                term: snomedSearchTag.parent_snomed_tag.term,
                cui: snomedSearchTag.parent_snomed_tag.cui,
                operator: snomedSearchTag.operator,
                isChecked: true,
                children: [], // Initially set empty, will be filled after API response
              };

              const response = await getSnomedSearchDropDownLists(
                token,
                snomedSearchTag.parent_snomed_tag.cui
              );
              const tempMatchedItem = response.find(
                (item: any) =>
                  item.cui === snomedSearchTag.parent_snomed_tag.cui
              );

              // Update the dropdown data with children if matched
              if (tempMatchedItem) {
                updatedDropDownData.children = setIsCheckedRecursively(
                  tempMatchedItem.children
                );
              }

              return updatedDropDownData;
            }
            return null; // Return null if term is empty to filter out later
          })
        );

        // Filter out null values (those items with empty terms)
        const filteredSnomedDropDownDataArray =
          tempSnomedDropDownDataArray.filter(Boolean);

        // Update Zustand state with the fully processed data array
        setSnomedDropDownDataArray(filteredSnomedDropDownDataArray);
        setIsSnomedSearchLoader(false);
      } catch (error) {
        console.error("Error building hierarchical dropdown:", error);
      }
    };

    const getSearchParms = async (id: number) => {
    if (token) {
        try {
        const searchParms = await getSearchParams(id, token);
        if (searchParms) {
          if (searchParms.path === "studies") {
            setSearchType("study");
            const snomedSearchRequest = searchParms.params?.snomed_search_request;
            if (snomedSearchRequest) {
              if(snomedSearchRequest.filters && snomedSearchRequest.filters.sex) {
                if (snomedSearchRequest.filters.sex.find((value:any) => value === 'O') && snomedSearchRequest.filters.sex.find((value:any) => value !== 'U')) {
                  snomedSearchRequest.filters.sex.push('U');
                }
              }
              setSnomedSearchRequest(snomedSearchRequest);
              const snomedSearchTerms = snomedSearchRequest?.searchTerms;
              if (snomedSearchTerms?.length > 0) {
                BuildSnomedTagDropdown(snomedSearchRequest.searchTerms);
              }
              if (snomedSearchRequest.pagination) {
                setStudiesPagination(snomedSearchRequest.pagination.offset);
                setSnomedPaginationOffset(snomedSearchRequest.pagination.offset);
                setSelectedSnomedPageSize(snomedSearchRequest.pagination.pageSize);
              }
              const filters = snomedSearchRequest.filters;
                  updateFilter(filters);
            }else{
              const filters = searchParms.params.search_request.filters;
              updateFilter(filters);
              if (searchParms.params.search_terms) {
                setSearchTerms(searchParms.params.search_terms);
              }
              if (searchParms.params?.filter_groups?.length) {
                setAdvancedFiltersEnabled(true);
                setFilterGroups(searchParms?.params?.filter_groups);
              }
            }
            if (searchParms.params?.filter_groups?.length) {
              setAdvancedFiltersEnabled(true);
              setFilterGroups(searchParms.params.filter_groups);
            }
          } else if (searchParms.path === "longitudinal") {
            setSearchType("patient");
            if (searchParms.params.search_request.pagination) {
              setPatientPagination(searchParms.params.search_request.pagination.offset);
              setPatientPaginationOffset(searchParms.params.search_request.pagination.offset);
              setSelectedPatientPageSize(searchParms.params.search_request.pagination.pageSize);
            }
            if (searchParms.params.sort) {
              updatePatientSortArray(searchParms?.params.sort);
            }
            setTimeout(() => {
              if (searchParms?.params?.filter_groups2) {
                setAdvancedFiltersEnabled(true);
                setAdvancedFilters2(searchParms?.params?.filter_groups2);
                setFilterGroups2(searchParms?.params?.filter_groups2);
                setAdvancedFiltersEnabled2(true);
              }
              if (searchParms?.params?.filter_groups1) {
                setAdvancedFiltersEnabled(true);
                setAdvancedFilters1(searchParms?.params?.filter_groups1);
                setFilterGroups1(searchParms?.params?.filter_groups1);
                setAdvancedFiltersEnabled1(true);
              }
              setTimeBetween(searchParms.params.timeBetween);
              setPatientFilters(searchParms?.params.search_request);
            }, 3000);
          }
        }
        } catch (error) {
          console.log(error);
        }
      }
    };
    useEffect(()=>{
        if(urlSearchId){
            getSearchParms(urlSearchId);
        }
    },[urlSearchId,token])

    const handleNewsClose = () => {
        setIsNewsOpen(false);
        try {
            if (token) {
                updateSettings({
                    news_release_date: releaseDate
                }, token);
            }
        } catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        const getUserAgreement = async () => {
            try {
                if (token) {
                    const fetchedUserData = await getUserAgreementStatus(token,latest_contract_version);
                    if(fetchedUserData){
                      const accepted = fetchedUserData.data.accepted;
                      const status = fetchedUserData.data.status;
                      const version = fetchedUserData.data.contract_version; 
                      setContractVersion(version);
                      setAgreementStatus(status);
                      if (accepted === true) {
                          setShowTermsOfServiceModal(false);
                      } else {
                          setShowTermsOfServiceModal(true);
                          setIsNewsOpen(false);
                      }
      
                      if (status === 404) {
                          setShowTermsOfServiceModal(true);
                          setIsNewsOpen(false);
                      }

                      if(version !== latest_contract_version ) {
                          setShowTermsOfServiceModal(true);
                          setIsNewsOpen(false);
                      }

                      const fetchedNewsContent = await getNewsContent(token);
                      if (fetchedNewsContent.status === 200 && fetchedNewsContent?.data?.status !== 404) {
                          setNewsContent(fetchedNewsContent.data);
                          setReleaseDate(fetchedNewsContent?.data[0]?.date);
                          if (accepted){
                              setIsNewsOpen(true);
                          } 
                      }
                      else {
                          setIsNewsOpen(false);
                          setNewsContent([]);
                      }
                    }
                    
                }
            } catch (error) {
                console.error("Error fetching user agreement status:", error);
            }
        };
        getUserAgreement();
    }, [token]);

 
    const getOrganizationProjects = async () => {
        try {
            if (token) {
                setIsTeamProjectLoading(true);
                const response = await getTeamProjects(token);
                setIsTeamProjectLoading(false);
                if (response.status === 200) {
                    const teamProjects = response?.data;
                    if (teamProjects) {
                        const teamData: Project[] = [];
                        const teamCohort = teamProjects.map((item: any) => {
                            return item?.projects.map((element: any) => {
                                const project: Project = {
                                    id: element?.id,
                                    project_name: element?.project_name,
                                    cohort: element?.cohort,
                                    is_editable_by_team: element?.is_editable_by_team,
                                    email: item.email,
                                    userId: item.user_id
                                };
                                teamData.push(project);
                                return { email: item?.email, project_name: element?.project_name, id: element?.id, cohort: element.cohort, cohort_id: element?.cohort_id, is_editable_by_team: element?.is_editable_by_team, userId: item.user_id };
                            });
                        });
                        const organizationData = teamCohort.flat();
                        setTeamProjects(organizationData);
                        setOrganizationProjects(teamData)
                    }
                }
            }
        }catch(error){
          console.log(error);
        }
      };
 
      useEffect(()=>{
        getOrganizationProjects();
      },[token])
 
      useEffect(()=>{
        if(selectedProject?.id){
          const project = projects.find((project) => project.id === selectedProject?.id);
          if(project){
            setProjectsBelongsToUser(true);
          }else{
            setProjectsBelongsToUser(false);
          }
        }
     
      },[selectedProject])
 
    return (
      <>
      <div className="flex justify-between items-center sticky top-0 z-40 h-16 border-b border-gray-200 bg-white px-4 shadow-sm sm:px-6 lg:px-8 z-50">
          <div className="flex items-center xl:hidden z-50">
              <button
                  type="button"
                  className="flex items-center gap-x-4 lg:gap-x-6 hover:cursor-pointer"
                  onClick={() => setIsFiltersPanelOpen(!isFilterPanelOpen)}
              >
                  {isFilterPanelOpen ? <XMarkIcon className='w-8 h-8' /> : <Bars3Icon className='w-8 h-8' />}
              </button>
          </div>
          <div className="flex ml-auto space-x-6 items-center divide-x">
              <div className='flex space-x-4 items-center mb-2'>
                {
                            projects && projects?.length > 0 ?
                                <ProjectsDropdown teamProjects={teamProjects} />
                        :
                        null
                }
              </div>
              <div className='px-4'>
                    <UserButton/>
                  {/* <ProfileDropdown /> */}
              </div>
          </div>
      </div>
      <CreateNewProjectModal />
      <EditProjectNameModal />
      {showDeleteProjectModal && <DeleteProjectModal />}
      {isNewsOpen && 
      <NewsModal isOpen={isNewsOpen} onClose={handleNewsClose} content={newsContent}/>
      }
     {showAgreementModal && (
        <AgreementModal contractVersion={contractVersion} agreementStatus={agreementStatus} latestVersion={latest_contract_version}/>
     )}
  </>
 
    )
}