import { Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { useModalStore } from '../../store/modalStore'
import { createNewProject } from '../../utils/projects'
import { useAuthStore } from '../../store/authStore'
import { useState } from 'react'
import { useUserStore } from '../../store/userStore'
import { useToastStore } from '../../store/toastStore'
import { duplicateProjectWarningMessage, projectNameCompulsoryMessage, projectNameLengthExceedingMessage, successfulProjectCreationMessage } from '../common/Constants'
import IsEditableByTeam from './isEditableByTeam/IsEditableByTeam'
import { useProjectsStore } from '../../store/projectsStore'

interface WarningMessage{
  show:boolean;
  warningMessage:string;
}

export default function CreateNewProjectModal() {

    // Modal State
    const open = useModalStore(state => state.showCreateNewProjectModal);
    const setOpen = useModalStore(state => state.setShowCreateNewProjectModal);

    // User Store
    const refreshUser = useUserStore(state => state.refreshUser);
    const setSelectedProjectById = useUserStore(state => state.setSelectedProjectById);
    const projects=useUserStore(state => state.projects);
    const gradientUser = useUserStore((state) => state.gradientUser);
    const setSelectedProjectOwnerEmail = useUserStore(
      (state) => state.setSelectedProjectOwnerEmail
    );

    //Project Store
    const isEditableByTeam = useProjectsStore(state => state.isEditableByTeam);

    // Auth State
    const token = useAuthStore(state => state.token);

    // Toast Store
    const setShow = useToastStore(state => state.setShowCustomToast);
    const setToasterCreationMessage= useToastStore(state => state.setToasterCreationMessage);

    // Local State
    const [ projectName, setProjectName ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);
    const [warningMessage, setWarningMessage] = useState<WarningMessage>({ show: false, warningMessage: '' });
    
    // Handle "enter" keypress
    const handleCreateNewProject = async (e: any) => {
        if (e) e.preventDefault();
        const createdProjectName = projectName.trim();
        if (createdProjectName !== "" && createdProjectName.length <= 80) {
          const existingProject = projects.find((project: any) => project.project_name.toLowerCase() === createdProjectName.toLowerCase());
          if (existingProject) {
            setWarningMessage(duplicateProjectWarningMessage);
          } else {
          setIsLoading(true);
          const proj = await createNewProject(createdProjectName, isEditableByTeam, token);
          await refreshUser(token);
          setSelectedProjectOwnerEmail(gradientUser.email);
          setSelectedProjectById(proj.id);
          setOpen(false);
          setShow(true);
          setToasterCreationMessage(successfulProjectCreationMessage);
          setProjectName('');
          setIsLoading(false);
        }
        } else if (createdProjectName.length > 80) {
          setWarningMessage(projectNameLengthExceedingMessage);
        } else {
          setWarningMessage(projectNameCompulsoryMessage);
        }
    }

  useEffect(() => {
    if (warningMessage.show) {
      const timeout = setTimeout(() => {
        setWarningMessage({ show: false, warningMessage: '' });
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [warningMessage])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-100" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                  <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">Create a New Project</h3>
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                    <p>Enter the name of the project you would like to create.</p>
                  </div>
                  <form className="mt-5 sm:flex sm:items-center" onSubmit={handleCreateNewProject}>
                    <div className="w-full sm:max-w-xs">
                        <label htmlFor="email" className="sr-only">
                            Project Name
                        </label>
                        <input
                          disabled={isLoading}
                          type="text"
                          name="projectName"
                          id="projectName"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          placeholder="New Project"
                          value={projectName}
                          onChange={(e) => setProjectName(e.target.value)}
                        />
                    </div>
                    <button
                        disabled={isLoading}
                        type='button'
                        onClick={handleCreateNewProject}
                        className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:ml-3 sm:mt-0 sm:w-auto"
                    >
                        {!isLoading ?
                          "Create" :
                          "Creating..."
                        }
                      </button>
                    </form>
                    <IsEditableByTeam initiallyIsEditableByTeam={false} downloadStatus="OPEN" isLoading={isLoading} />
                </div>
                <div className="pointer-events-none flex items-center">
                                    {warningMessage?.show && (<><ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                        <p className="text-sm text-red-600 ml-2" id="email-error">
                                            {warningMessage?.warningMessage}
                                        </p></>)}
                                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
