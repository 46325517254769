import { useEffect, useRef, useState } from "react";
import { useUserStore } from "../../store/userStore"
import { requestCohortChartsData, requestStudiesCountInCohort, requestStudiesInCohort } from "../../utils/request_cohort";
import { useExportLimitStore } from "../../store/exportStore";
import { useAuthStore } from "../../store/authStore";
import { LoadingSpinner } from "../../components/common/loadingSpinner";
import { InformationCircleIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { useProjectsStore } from "../../store/projectsStore";
import NoStudiesInProject from "../../components/catalogDisplay/DisplayStates/NoStudiesInProject";
import { ExportButton } from "./components/ExportButton";
import { ProgressBar } from "./components/ProgressBar";
import { WipeButton } from "./components/WipeButton";
import StudyCard from "../../components/catalogDisplay/StudyCard";
import OrderCharts from "./components/OrderCharts";
import { useModalStore } from "../../store/modalStore";
import { PencilIcon } from '@heroicons/react/24/outline'
import DownloadCsv from "../../components/catalogDisplay/DownloadCsv";
import Pagination from "../../pages/Projects/components/ProjectsPagination";
import { debounce } from "../../utils/debounce";
import axios, { CancelTokenSource } from "axios";
import ResultsPerPage from "../../components/common/ResultsPerPage";
import { useChartsStore } from "../../store/chartsStore";
import { EditStats } from "./components/charts/editStats/EditStats";
import { chartsData } from "./components/charts/ChartOptions";

type ProjectsPageProps = {
  cohortCountAxiosCancelToken: React.MutableRefObject<CancelTokenSource | null>;
  searchStudiesAxiosCancelToken: React.MutableRefObject<CancelTokenSource | null>;
};


export default function ProjectsPage({cohortCountAxiosCancelToken, searchStudiesAxiosCancelToken} : ProjectsPageProps) {

    // User State
    const selectedProject = useUserStore(state => state.selectedProject);
    const token = useAuthStore(state => state.token);
    const downloadStatus = useUserStore(state => state.selectedProject)?.cohort.status;
    const isProjectBelongsToUser = useUserStore(state => state.isProjectBelongsToUser);
    const selectedProjectOwnerEmail = useUserStore(state => state.selectedProjectOwnerEmail);
    const isCohortStudiesFetching = useUserStore(state => state.isCohortStudiesFetching);
    const gradientUser = useUserStore(state => state.gradientUser);
    // Projects Store
    const studiesInProject = useProjectsStore(state => state.studiesInProject);
    const setStudiesInProject = useProjectsStore(state => state.setStudiesInProject);
    const prevStudiesRef = useProjectsStore(state => state.prevStudiesRef);
    const setPrevStudiesRef = useProjectsStore(state => state.setPrevStudiesRef);
    const prevIdRef = useProjectsStore(state => state.prevIdRef);
    const setPrevIdRef = useProjectsStore(state => state.setPrevIdRef);
    const setSelectedProjectForEditName = useProjectsStore(state => state.setSelectedProjectForEditName);
    const studiesCountInCohort = useProjectsStore(state => state.studiesCountInCohort);
    const setStudiesCountInCohort = useProjectsStore(state => state.setStudiesCountInCohort);
    const projectPaginationOffset = useProjectsStore(state => state.projectPaginationOffset);
    const isRetrievingStudiesInCohort = useProjectsStore(state => state.isRetrievingStudiesInCohort);
    const setIsRetrievingStudiesInCohort = useProjectsStore(state => state.setIsRetrievingStudiesInCohort);
    const selectedPageSize = useProjectsStore(state => state.selectedPageSize);
    const setSelectedPageSize = useProjectsStore(state => state.setSelectedPageSize);
    const studies = useProjectsStore(state => state.studies);
    const setStudies = useProjectsStore(state => state.setStudies);

    // Charts Store
    const setCohortsChartsData = useChartsStore(state => state.setCohortsChartsData);
    const setProjectPaginationOffset = useProjectsStore(state => state.setProjectPaginationOffset);
    const statCheckedItems = useChartsStore(state => state.statCheckedItems);

    const isNewProject = (prevIdRef.current !== selectedProject?.id);

    // Export Store
    const exportLimits = useExportLimitStore(state => state.exportLimit);
    const refreshExportLimit = useExportLimitStore(state => state.refreshExportLimit);

    // Modal State
    const setShowEditProjectNameModal = useModalStore(state => state.setShowEditProjectNameModal);
    const seriesHasThumbnail = useModalStore(state => state.seriesHasThumbnail);

    // Local State
    const [ isRetrievingCharts, setIsRetrievingCharts ] = useState<boolean>(false);
    const [ showStats, setShowStats ] = useState<boolean>(true);
    const [showRenameIcon, setShowRenameIcon] = useState(false);
    const [showPencilIconForUser, setPencilIconForUser] = useState<boolean>(true);
 
    const axiosChartSourceRef = useRef<CancelTokenSource | null>(null);
    const axiosExportLimitSource = useRef<CancelTokenSource | null>(null);
    const previousPaginationRef = useRef<any>(projectPaginationOffset);

    useEffect(() => {
        const fetchCohortsCount = async () => {
            if(selectedProject && token && selectedProjectOwnerEmail !== "" && !isCohortStudiesFetching) {
                if (cohortCountAxiosCancelToken.current) {
                    cohortCountAxiosCancelToken.current.cancel(
                    "Canceling previous request"
                  );
                }
                // Create a new CancelTokenSource for the current request
                cohortCountAxiosCancelToken.current = axios.CancelToken.source();
                try {
                    const cohortCountResult = await requestStudiesCountInCohort(selectedProjectOwnerEmail,selectedProject?.id, selectedProject?.cohort?.id, token, { cancelToken: cohortCountAxiosCancelToken.current.token });
                    setStudiesCountInCohort(cohortCountResult?.count);       
                } catch (error) {
                  if (axios.isCancel(error)) {
                    console.log("Request canceled", error.message);
                  } else {
                    console.error(error);
                  }
                }   
            } 
        }
        fetchCohortsCount();
    }, [selectedProject, isCohortStudiesFetching, isNewProject, token]);

    useEffect(() => {
        const fetchCohortsCharts = async () => {
            if(!isCohortStudiesFetching) {
                if (axiosChartSourceRef.current) {
                  axiosChartSourceRef.current.cancel("Canceling previous request");
                }
                // Create a new CancelTokenSource for the current request
                axiosChartSourceRef.current = axios.CancelToken.source();
                try {
                    if(token){
                    setIsRetrievingCharts(true);
                    if (selectedProject && selectedProjectOwnerEmail !== "") {
                        let selectedChartKeys: string[] = [];
                        Object.keys(statCheckedItems)
                        .filter((key) => statCheckedItems[key])
                        .forEach((title) => {
                            const chartField = chartsData.find((chart) => chart.title === title)?.chart_field;
                            if (chartField && !selectedChartKeys.includes(chartField)) {
                            selectedChartKeys.push(chartField);
                            }
                        });
                     const cohortChartResult = await requestCohortChartsData(selectedProjectOwnerEmail, selectedProject.id, selectedProject.cohort.id,selectedChartKeys, token, { cancelToken: axiosChartSourceRef.current.token });
                        setCohortsChartsData(cohortChartResult);
                    }
                 }
                } catch (error) {
                    if (axios.isCancel(error)) {
                        console.log('Request canceled', error.message);
                    } else {
                        console.error(error);
                    }
                }
                    setIsRetrievingCharts(false);
            }
        };
        
        fetchCohortsCharts();
    }, [selectedProject, isCohortStudiesFetching, selectedProjectOwnerEmail,statCheckedItems, token]);
    
       // Debounced version of cohort studies
    const debouncedFetchCohortStudies = debounce(() => {
        retrieveCohortStudies();
    }, 700);

    const debounceExportLimit = debounce((token) => {
        retriveExportLimit(token, )
    }, 700);

    const retriveExportLimit = async(token:string)=>{
        if (axiosExportLimitSource.current) {
            axiosExportLimitSource.current.cancel('Canceling previous limit request');
        }
        axiosExportLimitSource.current = axios.CancelToken.source();
        refreshExportLimit(token, axiosExportLimitSource);
    }

    const retrieveCohortStudies = async () => {
        if(!isCohortStudiesFetching)
        {// Cancel the prev existing request
            if (searchStudiesAxiosCancelToken.current) {
                searchStudiesAxiosCancelToken.current.cancel('Canceling previous request');
            }

            // Create a new CancelTokenSource for the current request
            searchStudiesAxiosCancelToken.current = axios.CancelToken.source();
            try {
                if(token){
            const isNewProject = (prevIdRef.current !== selectedProject?.id);
            if(selectedProject && selectedProjectOwnerEmail !== "") {
                setIsRetrievingStudiesInCohort(true);
                if(isNewProject){
                    setStudiesInProject([]);
                }
                const cohortResult = await requestStudiesInCohort(selectedProjectOwnerEmail, selectedProject.cohort.id, selectedPageSize, projectPaginationOffset.offset, selectedProject.id, token, { cancelToken: searchStudiesAxiosCancelToken.current.token });
                const offset:number = projectPaginationOffset.offset;        
                setStudies({
                ...studies,
                [offset]: cohortResult.studies
                });
                setStudiesInProject(cohortResult.studies); 
                setPrevStudiesRef(selectedProject.cohort.studies);
                setPrevIdRef(selectedProject.id);
            } 
            setIsRetrievingStudiesInCohort(false);            
                 } 
            }
            catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error(error);
            }
        }
        }

    }

    useEffect(() => {
        const prevPaginationOffset =  previousPaginationRef?.current.offset;
        const currentPaginationOffset = projectPaginationOffset.offset;      
        const offsetChanged = prevPaginationOffset !== currentPaginationOffset;
        const isNewProject = (prevIdRef.current !== selectedProject?.id);
        if(offsetChanged || isNewProject){
            const offset = offsetChanged ? currentPaginationOffset : prevPaginationOffset;

            if (offsetChanged !== undefined && studies[offset] !== undefined && !isNewProject) {
                setStudiesInProject(studies[offset]);
            } else {
                debouncedFetchCohortStudies();
                debounceExportLimit(token);
            } 
        }
        previousPaginationRef.current = projectPaginationOffset;
    }, [selectedProject, gradientUser, isCohortStudiesFetching, token,projectPaginationOffset.offset]);

    useEffect(() => {
        if (isProjectBelongsToUser) {
            setPencilIconForUser(true);
        }
        else {
            setPencilIconForUser(false);
        }
    }, [isProjectBelongsToUser])
 
    useEffect(() => {
        const isNewCohort = JSON.stringify(prevStudiesRef.current) !== JSON.stringify(selectedProject?.cohort?.studies);
        const rows = selectedProject?.cohort?.studies?.map((row: any) => row.row_id);
        const fetchedRows = studiesInProject.map((row: any) => row.row_id);

        const rowsRemoved = (rows && rows.length >= 0) ? studiesInProject.filter((study: any) => {
            return !rows.includes(study.row_id)
        }).length > 0 : false;

        const rowsAdded = (rows && rows.length >= 0) ? rows.filter((row_id: any) => {
            return !fetchedRows.includes(row_id)
        }).length > 0 : false;

        if (rowsAdded) {
            if (searchStudiesAxiosCancelToken.current) {
                searchStudiesAxiosCancelToken.current.cancel();
            }
            // Create a new CancelTokenSource for the current request
            searchStudiesAxiosCancelToken.current = axios.CancelToken.source();
            
            const fetchCohortsWithStudies = async () => {
                if (selectedProject && searchStudiesAxiosCancelToken.current) {
                 try{
                    const isNewProject = (prevIdRef.current !== selectedProject?.id);
                    if(token && selectedProjectOwnerEmail  !== ""){
                    setIsRetrievingStudiesInCohort(true);
                    if(isNewProject){
                        setStudiesInProject([]);
                    }
                    const cohortResult = await requestStudiesInCohort(selectedProjectOwnerEmail , selectedProject.cohort.id, selectedPageSize, projectPaginationOffset.offset, selectedProject.id, token, { cancelToken: searchStudiesAxiosCancelToken.current.token });
                    const offset:number = projectPaginationOffset.offset;        
                    setStudies({
                        ...studies,
                        [offset]: cohortResult.studies
                    });
                    setStudiesInProject(cohortResult.studies);
                    setPrevStudiesRef(selectedProject.cohort.studies);
                    setIsRetrievingStudiesInCohort(false);
                    setPrevIdRef(selectedProject.id);
                    previousPaginationRef.current = projectPaginationOffset;
                    }
                } catch (error){
                    if (axios.isCancel(error)) {
                        console.log('Request canceled', error.message);
                    }else {
                        console.error(error);
                    }
                }
                }
            };
            fetchCohortsWithStudies();
        } else if (rowsRemoved && !rowsAdded && rows && rows.length >= 0 ) {
            setStudiesInProject(studiesInProject.filter((study: any) => {
                    return rows.includes(study.row_id)
                }
            ));
        }


    }, [selectedProject?.cohort, token, selectedProjectOwnerEmail])

    const handleToRenameProject = (project: any) => {
      setShowEditProjectNameModal(true);
      const selectedProjectForEditName = {
        id: project.id,
        project_name: project.project_name,
        isEditableByTeam: project.is_editable_by_team,
      };
      setSelectedProjectForEditName(selectedProjectForEditName);
    };

    const handleSelectPageSize = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedPage = Number(event.target.value);
        setProjectPaginationOffset(0);
        setSelectedPageSize(selectedPage);
    }
    
    useEffect(() => {
        const handleScroll = () => {
          window.scrollTo(0, 0);
        };
  
        handleScroll();
      }, [selectedPageSize]);
      
    useEffect(()=>{
        retrieveCohortStudies();
    },[selectedPageSize])

    return (
        <div className="flex flex-col h-screen">
            <div className="flex flex-row items-center my-6 justify-between z-30">
                <div className="flex items-center " onMouseEnter={() => setShowRenameIcon(true)} onMouseLeave={() => setShowRenameIcon(false)}>
                    <h1 className="ml-4 text-xl font-semibold text-gray-900 border-b-2 border-slate-300 line-clamp-6 w-8/12 hover:line-clamp-8 hover:text-xl" style={{ overflowWrap: 'break-word', wordBreak: 'break-all' }} >{selectedProject?.project_name}</h1>
                    {showPencilIconForUser &&<div>
                        <PencilIcon className={`h-[20px] cursor-pointer ${showRenameIcon ? 'text-grey-700': 'text-white'} w-[20px] ml-2`}
                            onClick={() => handleToRenameProject(selectedProject)}
                        />
                    </div>}
                    <button
                        type="button"
                        disabled={selectedProject?.cohort?.studies?.length === 0}
                        onClick={() => setShowStats(!showStats)}
                        className="whitespace-nowrap ml-2 mt-2 rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        {selectedProject?.cohort?.studies?.length === 0 ?
                            "No Stats Available"
                        : !showStats ?
                            <span className="flex items-center"><ChevronDownIcon className="h-5 w-5"/>Show Stats</span> :
                            <span className="flex items-center"><ChevronUpIcon className="h-5 w-5" />Hide Stats</span>}
                    </button>
                    <EditStats/>
                </div>           
                <div className="hidden sm:flex flex-row w-1/4 items-center justify-end p-2">
                    <p className="flex justify-start py-2 text-base text-gray-800 font-medium mr-4 whitespace-nowrap">Remaining Exports:</p>
                    <div className="flex flex-col space-y-2">
                        <span className="flex items-center"><ProgressBar label="Monthly" usage={exportLimits.monthly.usage} quota={exportLimits.monthly.quota} project={selectedProject}/></span>
                        <span className="flex items-center"><ProgressBar label="Total" usage={exportLimits.total.usage} quota={exportLimits.total.quota} project={selectedProject}/></span>
                    </div>
                </div>
                <div className="flex items-center pr-8 gap-2">
                <div className="flex flex-row items-center gap-x-8">                    
                        <div>
                        <p className="text-gray-600 text-sm mx-2">
                            { studiesCountInCohort && studiesCountInCohort < 1 ? (
                                <span className="text-gray-600 text-sm">Loading Study Count...</span>
                            ) : studiesCountInCohort && studiesCountInCohort > 0 ? (
                                <>
                                    Showing 
                                    <span className="font-medium">&nbsp;{projectPaginationOffset.offset * selectedPageSize + 1}</span> - 
                                    <span className="font-medium">
                                        &nbsp;                                    
                                        {
                                        // Account for if "highest" study is more than number of Cohort Studies
                                        (((projectPaginationOffset.offset + 1) * selectedPageSize) >= studiesCountInCohort ) ?
                                             studiesCountInCohort :
                                            (projectPaginationOffset.offset + 1) * selectedPageSize
                                        }
                                    </span> of <span className="font-medium">{studiesCountInCohort}</span>
                                </>
                            ) : null }
                        </p>
                        </div>
                        <Pagination selectedPageSize={selectedPageSize}/>
                    </div>
                    <div className="flex justify-between gap-2">
                        <WipeButton/>
                        <DownloadCsv module="cohort"/>
                        <ExportButton/>
                    </div>
                </div>
            </div>
            <div className="flex items-center ml-4">
                <InformationCircleIcon className="h-4 w-4 text-blue-600 mr-1" />
                <p className="text-gray-500 text-xs">You can select or create a different project in the dropdown above</p>
            </div>
            {downloadStatus === "INITIATED" && !isRetrievingStudiesInCohort &&
                <div className={`${seriesHasThumbnail? "w-[27%]": "w-[25%]"} flex items-center ml-4 mt-2 bg-blue-600`}>
                    <p className="text-white text-sm text-center px-1">Export in progress. Delivery estimation:
                        <span>
                            {seriesHasThumbnail ?  " 3 to 14 business days" : " 2 business days" }
                        </span>
                    </p>
                </div>  
            }
            {
                studiesCountInCohort === 0 || studiesCountInCohort=== null ?
                    null
                :
                !showStats ? null : (
                    <div className="px-8 mt-4">
                        {isRetrievingCharts && !isRetrievingStudiesInCohort ? 
                        <LoadingSpinner message="Fetching your charts..."/> : 
                        (!isRetrievingCharts && <OrderCharts />)
                        }
                    </div>
                )
            }
            <div className="px-8 mt-8">
                {
                    isRetrievingStudiesInCohort ? <LoadingSpinner message="Fetching your selected studies..."/> : (
                        studiesInProject.length > 0 ?
                        studiesInProject.map((studyMetadata, index) => {
                                return (
                                    <>
                                        <StudyCard studyMetadata={studyMetadata} key={`${studyMetadata.accession_number}-${index}`} page="projects" />
                                    </>
                                )
                            })
                        : <NoStudiesInProject />
                    )
                }
                </div>
            {studiesInProject.length > 0 &&
                <div className="pr-8 z-30">
                    {!isRetrievingStudiesInCohort && (
                        <div className="flex justify-end">
                            <ResultsPerPage 
                                selectedPageSize={selectedPageSize}
                                handleSelectPageSize={handleSelectPageSize}
                            />
                            <div className="flex justify-end py-4 items-center gap-x-4 pr-20">
                                <div>
                                    <p className="text-gray-600 text-sm mx-2">
                                        { studiesCountInCohort && studiesCountInCohort < 1 ? (
                                            <span className="text-gray-600 text-sm">Loading Study Count...</span>
                                        ) : studiesCountInCohort && studiesCountInCohort > 0 ? (
                                            <>
                                                Showing 
                                                <span className="font-medium">&nbsp;{projectPaginationOffset.offset * selectedPageSize + 1}</span> - 
                                                <span className="font-medium">
                                                    &nbsp;                                    
                                                    {
                                                    // Account for if "highest" study is more than number of Cohort Studies
                                                    (((projectPaginationOffset.offset + 1) * selectedPageSize) >= studiesCountInCohort ) ?
                                                        studiesCountInCohort :
                                                        (projectPaginationOffset.offset + 1) * selectedPageSize
                                                    }
                                                </span> of <span className="font-medium">{studiesCountInCohort}</span>
                                            </>
                                        ) : null }
                                    </p>
                                </div>
                                <Pagination selectedPageSize={selectedPageSize}/>
                            </div>
                        </div>
                    )}
                </div>
            }
        </div>
    )
}