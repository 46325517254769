import { create } from 'zustand';
import { Study } from "../types/search"
interface Project {
    id: number;
    project_name: string;
    isEditableByTeam: boolean;
}
interface StudiesByPageObject {
  [key: number]: Study[];
}
interface ProjectsStore {
    studiesInProject: any[];
    setStudiesInProject: (studies: any[]) => void;
    prevStudiesRef: React.MutableRefObject<any[]>;
    setPrevStudiesRef: (studies: any[]) => void;
    prevIdRef: React.MutableRefObject<number>;
    setPrevIdRef: (id: number) => void;
    selectedProjectForEditName:Project;
    setSelectedProjectForEditName: (selectedProjectForEditName: Project) => void;
    studiesCountInCohort: number | null;
    setStudiesCountInCohort: (count: number | null) => void;
    projectPaginationOffset: any;
    setProjectPaginationOffset: (offset: number) => void;
    isRetrievingStudiesInCohort: boolean;
    setIsRetrievingStudiesInCohort : (isRetrievingStudiesInCohort: boolean) =>void;
    isEditableByTeam: boolean;
    setIsEditableByTeam: (isEditableByTeam: boolean) => void;
    exportedStudies: any[];
    setExportedStudies: (exportedStudies: any[]) => void;
    exportedStudiesAccessionNumber: string[];
    setExportedStudiesAccessionNumber: (exportedStudiesAccessionNumber: string[]) => void;
    isTeamProjectLoading: boolean;
    setIsTeamProjectLoading: (isTeamProjectLoading: boolean) => void;
    selectedPageSize: number;
    setSelectedPageSize: (selectedPageSize:number)=>void;
    studies: StudiesByPageObject;
    setStudies: (studies: StudiesByPageObject) => void;
}

export const useProjectsStore = create<ProjectsStore>()((set) => ({
  prevIdRef: { current: 0 },
  setPrevIdRef: (selectedId) => set({ prevIdRef: { current: selectedId } }),
  studiesInProject: [],
  setStudiesInProject: (studies) => set({ studiesInProject: studies }),
  prevStudiesRef: { current: [] },
  setPrevStudiesRef: (studies) => set({ prevStudiesRef: { current: studies } }),
  selectedProjectForEditName: {id:0, project_name:'', isEditableByTeam: false},
  setSelectedProjectForEditName: (selectedProjectForEditName) => set({ selectedProjectForEditName }),
  studiesCountInCohort: null,
  setStudiesCountInCohort: (count) => set({ studiesCountInCohort: count }),
  projectPaginationOffset: {
    offset: 0,
    pageSize: 10
  },
  setProjectPaginationOffset: (offset: number) =>
    set((state) => {
      return {
        ...state,
        projectPaginationOffset: {
          ...state.projectPaginationOffset,
          offset: offset
        }
      };
    }),
    isRetrievingStudiesInCohort:false,
    setIsRetrievingStudiesInCohort: (isRetrievingStudiesInCohort:boolean)=>set({isRetrievingStudiesInCohort}),
  isEditableByTeam: false,
  setIsEditableByTeam: (isEditableByTeam: boolean) => set({ isEditableByTeam }),
  exportedStudies: [],
  setExportedStudies: (exportedStudies) => set({ exportedStudies }),
  exportedStudiesAccessionNumber: [],
  setExportedStudiesAccessionNumber: (exportedStudiesAccessionNumber) => set({ exportedStudiesAccessionNumber }),
  isTeamProjectLoading: false,
  setIsTeamProjectLoading: (isTeamProjectLoading) => set({ isTeamProjectLoading }),
  selectedPageSize:10,
  setSelectedPageSize: (selectedPageSize) => set({selectedPageSize}),
  studies: {},
  setStudies: (studies: StudiesByPageObject) => set({ studies }),
}));

