import './App.css';
import Main from './pages/Main';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Projects from './pages/Projects/Projects';
import Layout from './components/Layout';
import { 
  ClerkProvider,
  RedirectToSignIn,
  SignIn,
  SignUp,
  SignedIn,
  SignedOut,
 } from '@clerk/clerk-react';
import OrgSettings from './pages/OrgSettings/OrgSettings';
import UploadCohortCSV from './pages/UploadCsv/UploadCohortCSV';
import { useRef } from 'react';
import { CancelTokenSource } from 'axios';


function App() {
  const navigate  = useNavigate();

  const cohortCountAxiosCancelToken = useRef<CancelTokenSource | null>(null);
  const searchStudiesAxiosCancelToken = useRef<CancelTokenSource | null>(null);

  return (
    <>
    <ClerkProvider 
      publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY!} 
      navigate={(to) => navigate(to)}
      >
      <Routes>
        <Route path="/sign-in/*" element={<SignIn routing="path" path="/sign-in" appearance={{ 
          elements: { 
            footer: { display: "none"},
            rootBox: "mx-auto mt-48"
          }
        }}/>}/>
        <Route path="*" element={
          <>
          <SignedIn>
            <Layout>
            <Routes>
              {["/","/share/:id"].map(path => <Route path={path} element={<Main cohortCountAxiosCancelToken={cohortCountAxiosCancelToken} searchStudiesAxiosCancelToken={searchStudiesAxiosCancelToken} />} />)}
              <Route path="/projects" element={<Projects cohortCountAxiosCancelToken={cohortCountAxiosCancelToken} searchStudiesAxiosCancelToken={searchStudiesAxiosCancelToken} />} />
              <Route path="/org_settings" element={<OrgSettings/>} />
              <Route path="/upload" element={<UploadCohortCSV cohortCountAxiosCancelToken={cohortCountAxiosCancelToken} />} />              
            </Routes>
            </Layout>
          </SignedIn>
          <SignedOut>
            <RedirectToSignIn/>
          </SignedOut>
          </>
        }/>
      </Routes>
    </ClerkProvider>
    </>
  );
}

export default App;
