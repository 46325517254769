import { create } from 'zustand';
import { Project } from '../types/project';
import { getAllUser } from '../utils/request_user';

interface GradientUser {
    email: string;
    role: 'USER' | 'ADMIN' | 'SUPER_ADMIN';
    subscribed: boolean;
}

// Advanced Filters should be bundled into this store and format
interface UserStore {
    projects: Project[];
    setProjects: (projects: Project[]) => void;
    selectedProject: Project | null;
    setSelectedProject: (project: Project) => void;
    setSelectedProjectById: (id: number) => void;
    updateCohort: (cohort: any) => void;
    updateTeammateCohort: (cohort: any) => void;
    gradientUser: GradientUser;
    setGradientUser: (user: GradientUser) => void;
    refreshUser: (token: any) => Promise<void>;
    organizationProjects: Project[];
    setOrganizationProjects: (organizationProjects: Project[]) => void;
    isProjectBelongsToUser:boolean;
    setProjectsBelongsToUser: (project: boolean) => void;
    selectedProjectOwnerEmail: string;
    setSelectedProjectOwnerEmail: (email: string) => void;
    isCohortStudiesFetching: boolean;
    setIsCohortStudiesFetching: (isCohortStudiesFetching: boolean) => void;
}

export const useUserStore = create<UserStore>()((set) => ({
    projects: [],
    setProjects: (projects: Project[]) => set({ projects }),
    selectedProject: null,
    setSelectedProject: (project: Project) => set(state => {
        return {
            selectedProject: project
        }
    }),
    setSelectedProjectById: (id: number) =>
        set((state) => {
            let project = state.projects.find((project) => project.id === id) || state.organizationProjects.find((project) => project.id === id);
            if (project !== undefined && (project?.email === "" || project?.email === undefined)) {
                project = { ...project, email: state.gradientUser.email };
            }
            return project ? {
            selectedProject: project
        } : state;
    }),
    updateCohort: (cohort: any) => set(state => {
        if (state.selectedProject === null) return state;
        const updatedProjects = state.projects.map((project) => {
            if (project.id === state?.selectedProject?.id) {
                return {
                ...project,
                cohort: cohort,
                };
            }
            return project;
            });
        return {
            selectedProject: {
                ...state.selectedProject,
                cohort: cohort
            },
            projects:updatedProjects
        }
    }),
    updateTeammateCohort: (cohort: any) => set(state => {
        console.log(state.organizationProjects);
        
        if (state.selectedProject === null) return state;
        const updatedProjects = state.organizationProjects.map((project) => {
            if (project.id === state?.selectedProject?.id) {
                return {
                ...project,
                cohort: cohort,
                };
            }
            return project;
            });
        return {
            selectedProject: {
                ...state.selectedProject,
                cohort: cohort
            },
            projects:updatedProjects
        }
    }),
    gradientUser: {
        email: '',
        role: 'USER',
        subscribed: false
    },
    setGradientUser: (user: GradientUser) => set({ gradientUser: user }),
    refreshUser: async(token: any) => {
        const allUser = await getAllUser(token);
        set({ projects: allUser.data.projects});
        set({ gradientUser: {
            email: allUser.data.email,
            role: allUser.data.role,
            subscribed: allUser.data.subscribed
        }})
    },
    organizationProjects: [],
    setOrganizationProjects: (organizationProjects: Project[]) => set({ organizationProjects }),
    isProjectBelongsToUser:false,
    setProjectsBelongsToUser: (projects: boolean) => set({ isProjectBelongsToUser: projects }),
    selectedProjectOwnerEmail: '',
    setSelectedProjectOwnerEmail: (email: string) => set({ selectedProjectOwnerEmail: email }),
    isCohortStudiesFetching: false,
    setIsCohortStudiesFetching: (isCohortStudiesFetching: boolean) => set({ isCohortStudiesFetching})
}))