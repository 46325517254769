import { useChartsStore } from "../../../store/chartsStore";
import { chartsData, ChartTitles, getChartComponent } from "./charts/ChartOptions";

export default function OrderCharts() {
  // Charts Store
  const statCheckedItems = useChartsStore((state) => state.statCheckedItems);
  const cohortsChartsData = useChartsStore((state) => state.cohortsChartsData);
  return (
    <div className="rounded-lg bg-white shadow border py-4">
      {/* <h3 className="text-lg font-medium text-gray-800 ml-8">
          Project Statistics
        </h3> */}
      <div className="overflow-x-auto">
        <div className={`grid grid-flow-col ${chartsData?.filter((chart) => statCheckedItems[chart.title]).length < 5 ? 'auto-cols-max' : 'auto-cols-[minmax(250px,_1fr)]'}`}>
          {chartsData
            ?.filter(
              (chart) =>
                statCheckedItems[chart.title] &&
                chart.chart_field in cohortsChartsData &&
                cohortsChartsData[
                  chart.chart_field as keyof typeof cohortsChartsData
                ] &&
                Object.keys(
                  cohortsChartsData[
                    chart.chart_field as keyof typeof cohortsChartsData
                  ]!
                ).length !== 0
            )
            ?.map((chart, index) => (
              <div className="flex flex-col" key={index}>
                <p className="text-sm text-center font-medium">{chart.title} Distribution</p>
                {getChartComponent(chart.title as ChartTitles)}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}