import { useEffect, useState } from "react";
import { useModalStore } from "../../../store/modalStore";
import { useUserStore } from "../../../store/userStore";
import OrderConfirmationModal from "./OrderModal";
import { useProjectsStore } from "../../../store/projectsStore";
import RemoveExportedStudies from "./RemoveExportedStudies";
import { getAccessionNumberOfPreviouslyExportedStudies } from "../../../utils/projects";
import { useAuthStore } from "../../../store/authStore";
import { LoadingSpinner } from "../../../components/common/loadingSpinner";


export function ExportButton() {

    // Projects Store
    const downloadStatus = useUserStore(state => state.selectedProject)?.cohort.status;
    const isProjectBelongsToUser = useUserStore(state => state.isProjectBelongsToUser);
    const isRetrievingStudiesInCohort = useProjectsStore(state => state.isRetrievingStudiesInCohort);
    const projects = useUserStore(state => state.projects)
    const selectedProject = useUserStore(state => state.selectedProject)
    const organizationProjects = useUserStore(state => state.organizationProjects);
    const token = useAuthStore(state => state.token);
    const setExportedStudies = useProjectsStore(state => state.setExportedStudies);
    const setExportedStudiesAccessionNumber = useProjectsStore(state => state.setExportedStudiesAccessionNumber);
    const studiesInProject = useProjectsStore(state => state.studiesInProject);

    // Modal State
    const setShowOrderConfirmationModal = useModalStore(state => state.setShowOrderConfirmationModal);
    const showOrderConfirmationModal = useModalStore(state => state.showOrderConfirmationModal);
    const setShowExportedStudiesRemoveModal = useModalStore(state => state.setShowExportedStudiesRemoveModal);
    const showExportedStudiesRemoveModal = useModalStore(state => state.showExportedStudiesRemoveModal);

    //local store
    const [ selectVolumeOpen, setSelectVolumeOpen ] = useState(false);
    const [isExporting, setIsExporting] = useState(false);

    const handleExport = async () => {
      if (!token) {
        return;
      }
      try {
        setIsExporting(true);
        const exportedByUser = projects.flatMap((project) =>
          project.cohort?.status && project.cohort?.status !== "OPEN"
            ? project.cohort.studies?.filter((exportedStudies) =>
                selectedProject?.cohort?.studies.some(
                  (study) => study.row_id === exportedStudies.row_id
                )
              )
            : []
        );
        const exportedByTeam = organizationProjects.flatMap((teamMatesProject) =>
          teamMatesProject.cohort?.status && teamMatesProject.cohort?.status !== "OPEN"
            ? teamMatesProject.cohort.studies?.filter((arrStudy) =>
                selectedProject?.cohort?.studies.some(
                  (study) => study.row_id === arrStudy.row_id
                )
              )
            : []
        );
        const exportedProjects = [...exportedByUser, ...exportedByTeam].map(
          (obj) => obj.row_id
        );
        if (exportedProjects.length > 0) {
          if (selectedProject) {
            setExportedStudies(exportedProjects);
            const orderStatus =
              await getAccessionNumberOfPreviouslyExportedStudies(
                selectedProject?.id,
                selectedProject?.cohort.id,
                token,
                exportedProjects
              );
            if (orderStatus.results && orderStatus.results?.length > 0) {
              const exportedAccessionNumber = orderStatus.results.map(
                (obj: any) => obj.accession_number
              );
              setExportedStudiesAccessionNumber(exportedAccessionNumber);
            }
          }
          setShowExportedStudiesRemoveModal(true);
        } else {
          setShowOrderConfirmationModal(true);
        }
      } catch (error) {
        console.error(error);
      }
      
    };

    useEffect(() => {
      if (!showExportedStudiesRemoveModal && !showOrderConfirmationModal) {
        setIsExporting(false);
      }
    }, [showExportedStudiesRemoveModal, showOrderConfirmationModal]);

    const handleDropDown = async (value: boolean) => {
        if(!isProjectBelongsToUser){
            setSelectVolumeOpen(value);
        }
      };

    return (
        <><div onMouseEnter={() => handleDropDown(true)} onMouseLeave={() => handleDropDown(false)}>
            {/* Button should not work if studies.length === 0 */}
            <button
                onClick={handleExport}
                disabled={downloadStatus !== "OPEN" || !isProjectBelongsToUser || isRetrievingStudiesInCohort || studiesInProject.length === 0 }
                className="inline-flex items-center whitespace-nowrap rounded-lg px-3 py-2 text-sm font-semibold shadow-sm disabled:text-gray-50 disabled:bg-gray-500 disabled:cursor-not-allowed bg-blue-500 border-blue-700 text-white hover:bg-blue-500">
                {downloadStatus === "OPEN" ? "Export" : downloadStatus === "INITIATED" ? "Export Initiated" : "Exported"}
                {isExporting && (
                  <span className="px-1">
                    <LoadingSpinner message="" size="sm" flex="row" />
                  </span>
                )} 
            </button>
            {showOrderConfirmationModal && <OrderConfirmationModal />}
            {showExportedStudiesRemoveModal && <RemoveExportedStudies />}
            </div><div
            id="dropdownHover"
            className={`absolute ${selectVolumeOpen ? "" : "hidden"} rounded-lg overflow-hidden bg-gray-800 text-white text-sm mt-12 whitespace-nowrap w-auto left-auto ${downloadStatus === "INITIATED" ? "ml-2" : "ml-12"}`}>
                <ul className="py-2 w-full text-sm text-gray-700 dark:text-gray-200 rounded-lg" aria-labelledby="dropdownHoverButton">
                    <li className="text-white px-1">
                        Action cannot be performed for a teammate.
                    </li>
                </ul>
            </div></>
    )
}