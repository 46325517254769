import { 
    AdvancedFilterGroupComponent,
    AdvancedFilterSearchRequest,
    AdvancedFilters,
    AdvancedFilterGroup
} from "../types/advancedFilters";

export function findUniqueInstancesOfProtocolNameAndSliceThickness(series: any[]) {
    const uniqueInstances: string[] = [];
    series.forEach((instance) => {
        const { ProtocolName, SliceThickness, Modality } = instance;
        if (Modality === "PR") return;
        const key = `${ProtocolName} @ ${SliceThickness}`;
        if (!uniqueInstances.includes(key)) {
            uniqueInstances.push(key);
        }
    });
    return uniqueInstances;
}


export function makeStudyReportReadable(raw_report: any, institution_name: string): string {
    // Check to see if it is a flava report which has a different format
    if (institution_name.includes('flava')) {
        return cleanFlavaReport(raw_report);
    }

    let report = raw_report

    // Remove any new lines that have no text
    report = report.replace(" - ", '\n- ').replace('"', "")

    // Replace "|.br|" with a space
    report = report.replace(/\\\.br\\/g, " ");

    return report;
}

function cleanFlavaReport(report: string): string {

    const lines = report?.split('\n');

    // Rule 1: Empty or Single Character Lines
    const filteredReport1 = lines?.filter(line => !line.match(/^s*\S?\s*$/));

    // Rule 2: Lines with specific words
    const filteredReport2 = filteredReport1?.filter(line => !line.match(/\b(AND|Yes|No|finish reading|pass to section| complete)\b/));

    // Rule 3: Lines with (0/0) or (0/1) or (1/1) style numbers
    const filteredReport3 = filteredReport2?.filter(line => !line.match(/^\(\d+[/\.]?\d*\)$/));

    // Rule 4: Lines with ( A) and ( B) style words
    const filteredReport4 = filteredReport3?.filter(line => !line.match(/^\([A-Za-z]\)$/));

    // Rule 5: Lines with (2/3) (3/+) etc.
    const filteredReport5 = filteredReport4?.filter(line => !line.match(/^\(\d+[/+]?\d*\)/));
    
    // Rule 6: Lines with ( 1) and ( two) style words
    const filteredReport6 = filteredReport5?.filter(line => !line.match(/^\(\d+ [A-Za-z]+\)$/))

    // Rule 7: Lines with ( The), ( B), ( w)
    const filteredReport7 = filteredReport6?.filter(line => !line.match(/^\([^)]{2,}\)$/))

    // Rule 8: Lines with ()
    const filteredReport8 = filteredReport7?.filter(line => !line.match(/^\(\)$/));

    // Rule 9: Lines with 1-5 characters
    const filteredReport9 = filteredReport8?.filter(line => !line.match(/^.{1,5}$/gm))

    // Rule 10: Lines with "( ) YES" or "( ) NO" excluding \n
    const filteredReport10 = filteredReport9?.filter(line => !line.match(/^\(\s*\)\s*(YES|NO)$/gm))

    return filteredReport10?.join('\n');

}

export function expandModalityName(modality: string): string {
    if (modality === "CT") { return "Computed Tomography"; }
    else if (modality === "MR") return "Magnetic Resonance";
    else if (modality === "CR") return "Computed Radiography";
    else if (modality === "PR") return "Presentation State";
    else if (modality === "AU") return "Audio ECG";
    else if (modality === "SR") return "Structured Report";
    else if (modality === "US") return "Ultrasound";
    else if (modality === "Mg") return "Mammogram";
    else if (modality === "PT") return "PET";
    else if (modality === "XA") return "X-Ray Angiography";
    else if (modality === "RF") return "Radio Fluoroscopy";
    else if (modality === "DX") return "Digital X-Ray";
    else if (modality === "NM") return "Nuclear Medicine";
    else if (modality === "DR") return "Digital Radiography";
    else if (modality === "MG") return "Mammography";
    else if (modality === "IVUS") return "Intravascular Ultrasound";
    else if (modality === "DG") return "Diaphanography";
    else if (modality === "DO") return "DEXA";
    else if (modality === "ECG") return "Electrocardiography";
    else if (modality === "RG") return "Radiographic imaging";
    else if (modality === "IO") return "Intra-Oral Radiography";
    else if (modality === "XX") return "Other";
    else { return "Unknown Modality"}
}

export function showHighlightedSectionOfReport(report: string, keywords: string[]): string {
    let highlightedReport = report;
    keywords.forEach((keyword) => {
        const regex = new RegExp(keyword, "g");
        highlightedReport = highlightedReport.replace(regex, `<mark>${keyword}</mark>`);
    });
    return highlightedReport;
}

export function convertToAdvancedFilterSearchRequest(
    advancedFiltersArray: AdvancedFilterGroupComponent[],
    groupsMatch: 'AND' | 'OR' | 'NOOP'
): AdvancedFilterSearchRequest {
    // Here we want to check through the advanced Filters Array and if there is only one group set operator to NOOP
    // First, let's do it for the overall operator
    if (advancedFiltersArray.length === 1) {
        groupsMatch = 'NOOP';
    }

    // Now let's do it each of the groups
    advancedFiltersArray.forEach((group) => {
        const { filters } = group;
        if (filters.length === 1) {
            group.logic_operator = 'NOOP';
        }
    });


    // Convert from AdvancedFilterGroupComponent[] to AdvancedFilterGroup[]
    const advancedFilters: AdvancedFilterGroup[] = advancedFiltersArray.map((group) => {
        const { logic_operator, filters } = group;
        const value: AdvancedFilters[] = filters.map((filter) => {
            const { field, operator, value } = filter;
            return { field, operator: Array.isArray(value) && value?.includes('null') ? "CONTAINS NULL" : operator.toUpperCase(), value: Array.isArray(value) && value.length === 1 ? value.toString() : value };
        })
        return { operator: logic_operator, value };
    })

    return { operator: groupsMatch, value: advancedFilters };
}


export function allValueFieldsAreFilled (filter: AdvancedFilterSearchRequest): boolean {
    if (!filter || !filter.value) return false;

    for (let group of filter.value) {
        for (let condition of group.value) {
            // Base case: Check the value field directly
            if (condition.value === "" || condition.value === null || typeof condition.value === 'undefined') {
                return false;
            }
        }
    }

    return true;
};


export function whichGeoIsSource(source: string): string  {
    if(source === null) return "";
    if(source.includes('tachyeres') || source.includes('thryothor') || source.includes('xenops') || source.includes('peregrine') || source.includes('quelea')) return "United States";

    return "Brazil";
}

export function whichStateIsSource(source: string): string {
    if(source === null) return "";
    if(source?.includes('tachyeres')) return "New York / Texas";
    if(source?.includes('thryothor')) return "North Carolina";
    if(source?.includes('xenops')) return "Missouri";
    if(source.includes('peregrine')) return "Indiana";
    if(source.includes('quelea')) return "Texas";
    return "International";
}

export function whichTypeIsSource(source: string): string {
    if(source === null) return "";
    if(source.includes('tachyeres')) return "Rural";
    if(source.includes('thryothor')) return "Community";
    if(source.includes('xenops')) return "Rural";
    if(source.includes('auritus')) return "Telerad";
    if(source.includes('flava')) return "Telerad";
    if(source.includes('peregrine')) return "Urban";
    if(source.includes('quelea')) return "Urban";
    return "Community";
}

export function addOtherValueToChartData(sortedEntries: [string, number][], filteredBodyPartData: { [key: string]: number }): void {
    const otherValue = sortedEntries.filter(([key]) => !Object.keys(filteredBodyPartData).includes(key)).reduce((sum, [, value]) => sum + Number(value), 0);
    
    if (otherValue > 0) {
        filteredBodyPartData["Other"] = otherValue;
    }
}
