import axios, { AxiosError } from 'axios';
import { create } from 'zustand';

const server_url = process.env.REACT_APP_GRADIENT_HEALTH_SERVER_URL;

export async function requestExportLimit(jwt_token: string,  config = {}) {
    const response = await axios.get(`${server_url}/export/limit`, {
        ...config,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    })

    return response.data;
}

interface ExportLimit {
    monthly: {
        usage: number;
        quota: number;
        limit: number;
    };
    total: {
        usage: number;
        quota: number;
        limit: number;
    };
}

interface ExportLimitStore {
    exportLimit: ExportLimit;
    setExportLimit: (exportLimit: ExportLimit) => void;
    refreshExportLimit: (token: any, axiosExportLimitSource:any) => Promise<void>;
}


export const useExportLimitStore = create<ExportLimitStore>((set) => ({
    exportLimit: {
        monthly: { usage: 0, quota: 1000, limit: 0 },
        total: { usage: 0, quota: 12000, limit: 0 },
    },
    setExportLimit: (exportLimit: ExportLimit) => set({ exportLimit }),
    refreshExportLimit: async (token: string, axiosExportLimitSource:any) => {
        try {
            if(token){
                const exportLimitData = await requestExportLimit(token, { cancelToken: axiosExportLimitSource?.current?.token });
                set({ exportLimit: exportLimitData });
            }
        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError && axiosError.response) {
                console.error(`HTTP error: ${axiosError.response.status}, ${axiosError.response.data}`);
            } else {
                console.error('An unexpected error occurred:', error);
            }
        }
    },
}));