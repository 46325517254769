import axios from 'axios';

const server_url = process.env.REACT_APP_GRADIENT_HEALTH_SERVER_URL;


export async function getAllUser(jwt_token: string, config = {}) {
    // console.log(`https://gradient-health-bean.appspot.com/search?json=${encodeURIComponent(JSON.stringify(search))}`);
    const response: any = await axios.get(`${server_url}/user/all`, {
        ...config,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    }).then((r) => {
        return r;
    }, (err) => {
        return err.response;
    })

    return response;
}

export async function createUser(email: string|undefined, jwt_token: string) {
    // console.log(`https://gradient-health-bean.appspot.com/search?json=${encodeURIComponent(JSON.stringify(search))}`);
    const response: any = await axios.post(`${server_url}/user`, {
        email: email
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    }).then((r) => {
        return r;
    }, (err) => {
        return err;
    })

    return response;
}

export async function updateSettings(settings: any, jwt_token: string, config = {}) {
    // console.log(`https://gradient-health-bean.appspot.com/search?json=${encodeURIComponent(JSON.stringify(search))}`);
    const response: any = await axios.put(`${server_url}/settings`, settings, {
        ...config,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    }).then((r) => {
        return r;
    }, (err) => {
        return err;
    })

    return response;
}

export async function getUserAgreementStatus(jwt_token: string, latest_contract_version: string) {
    const response: any = await axios.get(`${server_url}/agreement`, {
        params: {
            contract_version: latest_contract_version
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    }).then((r) => {
        return r;
    }, (err) => {
        return err.response;
    })

    return response;
}

export async function createUserAgreementStatus( jwt_token: string, agreement: any,contract_version:string) {
    const response: any = await axios.post(`${server_url}/agreement`, {
        accepted:agreement,
        contract_version:contract_version
    },{
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt_token}`
        }
    }).then((r) => {
        return r;
    }, (err) => {
        return err;
    })

    return response;
}