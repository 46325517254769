import EmptyCatalogState from "./DisplayStates/emptyCatalog";
import Pagination from "./Pagination";
import NoResultsCatalog from "./DisplayStates/NoResultsCatalog";
import { useSearchStore } from "../../store/searchStore";
import { useAuthStore } from '../../store/authStore'
import { useUserStore } from '../../store/userStore'
import { useAdvancedFilterStore } from '../../store/advancedFiltersStore';
import { usePatientStore } from '../../store/patientStore';
import { useSearchTypeStore } from '../../store/searchTypeStore';
import { SearchResult, SearchRequest, AdvancedSearchRequest, SnomedInitialSearchRequest } from '../../types/search';
import { getBulkLongitudinalStudies, getSnomedSearchStudies } from '../../utils/request_studies';
import { addToCohort, bulkAddToCohort } from '../../utils/request_cohort';
import StudyCard  from "./StudyCard";
import { LoadingState } from "./DisplayStates/LoadingState";
import { useState, useEffect } from 'react';
import { LoadingSpinner } from '../common/loadingSpinner';
import { formatSearchQueryForLucene } from "../../utils/lucene_helpers";
import { useSnomedSearchStore } from "../../store/snomedSearchStore";

const DEFAULT_PAGE_SIZE = 10;
export default function BulkAdd() {

    // Search state
    const searchRequestPageOffset = useSearchStore(state => state.searchRequestPageOffset)
    const searchRequest = useSearchStore(state => state.searchRequest);
    const numberOfStudies = useSearchStore(state => state.numberOfStudies);
    // Auth state
    const token = useAuthStore(state => state.token);

    // Project state
    const selectedProject = useUserStore(state => state.selectedProject)
    const isProjectBelongsToUser = useUserStore(state => state.isProjectBelongsToUser)
    const projects = useUserStore(state => state.projects);
    const organizationProjects = useUserStore(state => state.organizationProjects);
    const updateCohort = useUserStore(state => state.updateCohort)
    const gradientUser = useUserStore(state => state.gradientUser);

    const advancedFiltersEnabled = useAdvancedFilterStore(state => state.advancedFiltersEnabled);
    const advancedFilters = useAdvancedFilterStore(state => state.advancedFilters);

    const searchType = useSearchTypeStore(state => state.searchType);

    const [ selectVolumeOpen, setSelectVolumeOpen ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ shouldTrigger, setShouldTrigger ] = useState(false);
    const [isShowToaster, setIsShowToaster] = useState(false);
    const [ exportInProgressToastShow, setExportInProgressToastShow ] = useState(false);
    const patientFilters = usePatientStore(state => state.filters);
    const patientPagination = usePatientStore(state => state.pagination);

    // Snomed Search state
    const snomedSearchRequest = useSnomedSearchStore(state => state.snomedSearchRequest );

    const handleDropDown = (value: boolean) => {
        if(!isProjectBelongsToUser && !selectedProject?.is_editable_by_team){
            setSelectVolumeOpen(false);
            setIsShowToaster(value);
            return
        } else if (selectedProject?.cohort.status !== "OPEN") {
            setSelectVolumeOpen(false);
            setExportInProgressToastShow(value);
            return;
        } else if (numberOfStudies === 0 || numberOfStudies === null) {
            setSelectVolumeOpen(false);
            return;
        }
        if (!isLoading) {
            setSelectVolumeOpen(value);
        }
    };

    const bulkAddStudiesToCohort = async (fetchedStudies: SearchResult) => {
      if (selectedProject !== null && token) {
        try {
          let allAdds = fetchedStudies.results.map(
            ({ institution, row_id }) => ({ institution, row_id })
          );
          const alreadyAccepted = new Set(
            selectedProject.cohort.studies.map((study) => study.row_id)
          );
          const exportedStudies = new Set(
            projects.flatMap((project) =>
              project.cohort?.status && project.cohort?.status !== "OPEN"
                ? project.cohort?.studies?.map((study) => study.row_id)
                : []
            )
          );
          const teammateExportedStudies = new Set(
            organizationProjects.flatMap((teamMatesProject) =>
              teamMatesProject.cohort?.status && teamMatesProject.cohort?.status !== "OPEN"
                ? teamMatesProject.cohort?.studies?.map((study) => study.row_id)
                : []
            )
          );
          // Filter out studies that are already accepted, exported, or exported by teammates
          allAdds = allAdds.filter(
            ({ row_id }) =>
              !alreadyAccepted.has(row_id) &&
              !exportedStudies.has(row_id) &&
              !teammateExportedStudies.has(row_id)
          );
          const updatedProjects = await bulkAddToCohort(
            selectedProject.cohort.id,
            selectedProject.id,
            allAdds,
            selectedProject.email,
            token
          );
          updateCohort(updatedProjects);
          setIsLoading(false);
        } catch (err) {
          console.error(err);
        }
      }
    };

    const selectVolume = (volume: number) => {
        if (searchType == "study" && volume && selectedProject !== null) {
            const retrieveVolume = async () => {
              setIsLoading(true);
              setSelectVolumeOpen(false);

              let fetchedStudies: SearchResult;
              let tempSearchRequest: SearchRequest | AdvancedSearchRequest | SnomedInitialSearchRequest;

              if (advancedFilters && advancedFiltersEnabled ) {
                  tempSearchRequest = {
                      ...snomedSearchRequest,
                      advancedFilters
                  }
              } else {
                  tempSearchRequest = snomedSearchRequest;
              }

              tempSearchRequest = {
                  ...tempSearchRequest,
                  pagination: {
                      pageSize: volume,
                      offset: tempSearchRequest.pagination.offset,
                      start_index: tempSearchRequest.pagination.offset * DEFAULT_PAGE_SIZE,
                  },
                  project_id: selectedProject?.id
              }

              fetchedStudies = await getSnomedSearchStudies(tempSearchRequest as SnomedInitialSearchRequest, token);                
              bulkAddStudiesToCohort(fetchedStudies);
            }

            retrieveVolume()
        } else if (searchType == "patient" && volume && selectedProject !== null) {
            const retrieveVolume = async () => {
              setIsLoading(true);
              setSelectVolumeOpen(false);

              let fetchedStudies: SearchResult;
              let tempFilters = patientFilters;

              tempFilters[0].search_summary = "";
              tempFilters[1].search_summary = "";
              tempFilters[0].search_query = formatSearchQueryForLucene(tempFilters[0].filters.searchTerms)
              tempFilters[1].search_query = formatSearchQueryForLucene(tempFilters[1].filters.searchTerms)
              fetchedStudies = await getBulkLongitudinalStudies(tempFilters, patientPagination.offset, volume,selectedProject?.id, token);
              bulkAddStudiesToCohort(fetchedStudies);
            }

            retrieveVolume()
        }
    }

    return (
        <div onMouseEnter={() => handleDropDown(true)} onMouseLeave={() => handleDropDown(false)} >
            <button disabled={isLoading || selectedProject?.cohort.status !== "OPEN" || (numberOfStudies === 0 || numberOfStudies === null)} className={`bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center ${(!isProjectBelongsToUser && !selectedProject?.is_editable_by_team) ? 'bg-gray-200': ''}`} type="button">
                Add
                {isLoading ? <span className="px-1"> <LoadingSpinner message="" size="sm" flex="row"/></span> : <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                </svg> }
            </button>
            <div id="dropdownHover" className={`${
                selectVolumeOpen ? "absolute" : "hidden"
            }`}>
                <ul className="py-2 w-full text-sm text-gray-700 dark:text-gray-200 rounded-lg bg-gray-800 mt-2" aria-labelledby="dropdownHoverButton">
                  <li onClick={() => selectVolume(50)}>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100 hover:text-black dark:hover:bg-gray-600 bg-gray-800 text-white">First 50</a>
                  </li>
                  <li onClick={() => selectVolume(100)}>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100 hover:text-black dark:hover:bg-gray-600 bg-gray-800 text-white">First 100</a>
                  </li>
                  <li onClick={() => selectVolume(300)}>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100 hover:text-black dark:hover:bg-gray-600 bg-gray-800 text-white">First 300</a>
                  </li>
                </ul>
            </div>
            <div  id="isExportInProgressToastShow" className={`${
                exportInProgressToastShow ? "absolute" : "hidden"
            }`}>
                <ul className="py-2 w-full text-sm text-gray-700 dark:text-gray-200 rounded-lg bg-gray-800 mt-2" aria-labelledby="dropdownHoverButton">
                    <li className="text-white px-2">
                    Export in progress
                    </li>
                </ul>
            </div>
            <div id="meassge" className={`${isShowToaster ? "absolute" : "hidden"} whitespace-nowrap w-auto right-1 left-auto`}>
                <ul className="py-2 w-full text-sm text-gray-700 dark:text-gray-200 rounded-lg bg-gray-800 mt-2" aria-labelledby="dropdownHoverButton">
                <li className="text-white px-2">Action cannot be performed for a teammate.</li>
                </ul>
            </div>
        </div>
    )
}