import { manufacturerSelectionsDefaultLength } from "../components/filterControls/manufacturerFilter/manufacturerOptions";
import { modalityOptionsDefaultLength } from "../components/filterControls/modalityFilter/modalityOptions";
import { SnomedInitialSearchRequest } from "../types/search";

export function nullCheckingInFilters(
    tempSearchRequest: SnomedInitialSearchRequest
): SnomedInitialSearchRequest {

    const { filters } = tempSearchRequest;

    // Add is_null: true if conditions are met for age
    if (filters.age.min === 0 && filters.age.max === 90) {
        filters.age.is_null = true;
    }

    // Add is_null: true if conditions are met for slice_thickness
    if (
        filters.slice_thickness.min === 0 &&
        filters.slice_thickness.max === 10
    ) {
        filters.slice_thickness.is_null = true;
    }

    // Update modality filter if it matches the default length
    if (filters.modality?.length === modalityOptionsDefaultLength) {
        filters.modality = [...filters.modality, "null", "UNKNOWN"];
    }

    // Update manufacturer filter if it matches the default length
    if (
        filters.manufacturer?.length === manufacturerSelectionsDefaultLength
    ) {
        filters.manufacturer = [...filters.manufacturer, "null", "UNKNOWN"];
    }

    // Update sex filter if it contains exactly ["M", "F", "O"]
    if (filters?.sex?.length) {
        const isOtherSelected = filters.sex.includes('O');
        if (isOtherSelected) {
            filters.sex = [...filters.sex.filter(sex => sex !== 'O' && sex !== 'U'), 'O', 'U'];
        } else {
            filters.sex = [...filters.sex.filter(sex => sex !== 'O' && sex !== 'U')];
        }
        const hasAllSexOptions = ["M", "F", "O", "U"].every(sex => filters.sex.includes(sex));

        if (hasAllSexOptions) {
            // Add "null" if it doesn't already exist
            if (!filters?.sex.includes("null")) {
                filters.sex = [...filters.sex, "null"];
            }
        } else {
            // Remove "null" if it exists
            filters.sex = filters.sex.filter(sex => sex !== "null");
        }
    }

    return tempSearchRequest;
}